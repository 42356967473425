<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Metricas</h1>
    </div>
    <br />

    <div class="d-flex flex-wrap">
      <div class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Solicitudes</h4>
            <p class="card-subtitle text-muted">Administracion de las solicitudes</p>
            <router-link :to="{name: 'metricas_solicitudes'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>

      <div v-if="$auth.can('metricas','etapas_listar')" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Etapas</h4>
            <p class="card-subtitle text-muted">Administracion de las etapas</p>
            <router-link :to="{name: 'metricas_etapas'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>

      <div v-if="$auth.can('metricas','catalogo_crear')" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Catálogos</h4>
            <p class="card-subtitle text-muted">Administracion de catálogos</p>
            <router-link :to="{name: 'metricas_catalogos'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>

      <div v-if="$auth.can('metricas','admin_listar')" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Configuración</h4>
            <p class="card-subtitle text-muted">Configuración del sistema</p>
            <router-link :to="{name: 'metricas_configuracion'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    mounted: function() {
      let menu = {title: 'Metricas'};

      menu.metricas = 'Inicio';
      menu.metricas_solicitudes = 'Solicitudes';

      if (this.$auth.can('metricas','etapas_listar'))
        menu.metricas_etapas = 'Etapas';

      if (this.$auth.can('metricas','catalogo_crear'))
        menu.metricas_catalogos = 'Catálogos';

      if (this.$auth.can('metricas','admin_listar'))
        menu.metricas_configuracion = 'Configuración';

      this.$store.commit('setMenu',[menu]);
    }
  }
</script>